.login-page {
    width: calc(100vw);
    height: calc(100vh);
}


.logo-continer {
    width: 15%;
    height: calc(90%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 100%;
    height: inherit;
    object-fit: contain;
}

.header-with-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    height: 14vh;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
}




.login-box {
    width: 416px;
    height: 555px;
    margin: 0px auto;
    overflow: hidden;
    /* padding: 29px 32px 31px 33px; */
    border-radius: 21px;
    border: solid 3px #000000;
}

.heading-box {
    display: flex;
    justify-content: center;
    align-items: end;
    height: 75px;
}

.heading-box span {
    font-family: Roboto-Bold;
    font-size: 1.6667vw;
    letter-spacing: -0.64px;
    color: #3988cc;
}

.input-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 175px;
    flex-direction: column;
}

.button-box-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
}

.btn-rounded-1 {
    width: 95px;
    height: 42px;
    border-radius: 100px;
    background-color: #8dcc56;
    font-family: Roboto-Medium;
    font-size: 1.1vw;
    letter-spacing: -0.36px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.info-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 78%;
    margin: 0px auto;
}

.info-box span {
    font-family: Roboto-Regular;
    font-size: 0.9vw;
    letter-spacing: -0.64px;
    text-align: left;
    color: #3988cc;
}

.info-box span a {
    font-family: Roboto-Medium;
    font-size: 0.9vw;
    color: #3988cc;
}

.save-box {
    display: flex;
    height: 55px;
    align-items: center;
    width: 78%;
    margin: 0px auto;
}

.seprater {
    display: flex;
    height: inherit;
    align-items: center;
    width: 100%;
    margin: 0px auto;
    justify-content: center;
}

.seprater .left-line {
    flex: 1;
    border: 1px solid #000000;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.seprater .center-txt-line {
    flex: 2;
    justify-content: center;
    align-items: center;
    display: flex;
}

.seprater .center-txt-line span {
    font-family: Roboto-Regular;
    font-size: 1em;
    letter-spacing: -0.64px;
    color: #000000;
}

.seprater .right-line {
    flex: 1;
    border: 1px solid #000000;
    background-color: #000000;
}

.create-acc {
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.btn-rounded-2 {
    width: 70%;
    height: 45px;
    border-radius: 100px;
    background-color: #8dcc56;
    font-family: Roboto-Medium;
    font-size: 14px;
    letter-spacing: -0.36px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.error-box {
    width: 100%;
    height: 28px;
}

.error {
    width: 75%;
    height: inherit;
    line-height: 1;
    margin: 0px auto;
}

.error span {
    font-size: 0.8vw;
    color: red;
}

@media only screen and (max-width: 1200px) {

    .login-box {
        width: calc(100vw - 12vw);
        height: calc(100vh - 28vh);
    }
}

@media only screen and (max-width: 912px) {
    .header-with-logo {
        height: calc(12vh);
    }

    .login-container {
        height: calc(88vh);
    }

    .heading-box {
        height: calc(100vh - 94vh);
    }

    .heading-box span {
        font-size: 6vw;
    }

    .input-box {
        height: calc(100vh - 75vh);
    }

    .form-control {
        width: calc(100vw - 30vw);
        height: calc(100vh - 94vh);
    }

    .logo {
        width: 150%;
        height: inherit;
    }

    .button-box-1 {
        height: calc(100vh - 92vh);
    }

    .btn-rounded-1 {
        width: 155px;
        height: 65px;
        font-size: 3.8vw;
    }

    .info-box {
        height: calc(100vh - 94vh);
    }

    .info-box span {
        font-size: 2vw;
    }

    .info-box span a {
        font-size: 2vw;
    }

    .seprater .center-txt-line span {
        font-family: Roboto-Regular;
        font-size: 1em;
        letter-spacing: -0.64px;
        color: #3988cc;
    }

    .create-acc {
        height: 150px;
    }

    .btn-rounded-2 {
        width: 75%;
        height: 60px;
        font-size: 2.5vw;
    }

    .error-box {
        height: 24px;
    }

    .error span {
        font-size: 1.8vw;
    }
}

@media only screen and (max-width: 600px) {
    .header-with-logo {
        height: calc(10vh);
    }

    .login-container {
        height: calc(88vh);
    }

    .heading-box {
        height: calc(100vh - 94vh);
    }

    .heading-box span {
        font-size: 6vw;
    }

    .input-box {
        height: calc(100vh - 75vh);
    }

    .form-control {
        width: calc(100vw - 30vw);
        height: calc(100vh - 94vh);
    }

    .logo {
        width: 150%;
        height: inherit;
    }

    .button-box-1 {
        height: calc(100vh - 92vh);
    }

    .btn-rounded-1 {
        width: 80px;
        height: 38px;
        font-size: 3.8vw;
    }

    .info-box {
        height: calc(100vh - 94vh);
    }

    .info-box span {
        font-size: 3.3vw;
    }

    .info-box span a {
        font-size: 3.2vw;
    }

    .seprater .center-txt-line span {
        font-family: Roboto-Regular;
        font-size: 1em;
        letter-spacing: -0.64px;
        color: #3988cc;
    }

    .btn-rounded-2 {
        width: 75%;
        height: 38px;
    }

    .error-box {
        height: 24px;
    }

    .error span {
        font-size: 3vw;
    }
}