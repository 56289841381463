.textOnInput {
    position: relative;
}

.textOnInput label {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 2px;
    z-index: 1;
    color: #8dcc56;
}

.textOnInput label:after {
    content: " ";
    background-color: #fff;
    width: 100%;
    height: 13px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}

label {
    font-size: 1vw;
    font-weight: 500;
    display: inline-block;
    margin-bottom: .5rem;
    font-family: Roboto-Bold;
}

.form-control {
    box-shadow: none !important;
    outline: none !important;
    width: 310px;
    height: 50px;
    border-radius: 6px;
    border: solid 1.8px #8dcc56;
    font-family: Roboto-Regular;
    font-size: 16px;
    letter-spacing: -0.36px;
    color: #3988cc;
    padding-left: 10px;
}

.form-control::-webkit-input-placeholder {
    color: #3988cc;
    /*Change the placeholder color*/
    opacity: 0.3;
    /*Change the opacity between 0 and 1*/
}

.custom-checkbox {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

}

.left-box {
    flex: 1;
    align-items: center;
    height: 50px;
    display: flex;
}

.checkbox-false {
    border-radius: 6px;
    width: 20px;
    height: 20px;
    border: 2px solid #8dcc56;
    cursor: pointer;
}

.checkbox-true {
    border-radius: 6px;
    width: 20px;
    height: 20px;
    border: 2px solid #3988cc;
    background-color: #3988cc;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.right-box {
    flex: 8;
    height: 50px;
    align-items: center;
    display: flex;
}

.right-box span {
    font-family: Roboto-Regular;
    font-size: 1vw;
    color: #3988cc;
}

@media only screen and (max-width: 912px) {
    .right-box span {
        font-size: 2.5vw;
    }

    label {
        font-size: 1.8vw;
    }

    .custom-checkbox {
        width: 100%;
        height: calc(100vh - 93vh);
    }

    .checkbox-false {
        width: 24px;
        height: 24px;
    }

    .checkbox-true {
        width: 24px;
        height: 24px;
    }
    .form-control{
        font-size: 3vw;
    }
}

@media only screen and (max-width: 600px) {
    .right-box span {
        font-size: 3.5vw;
    }

    label {
        font-size: 3.5vw;
    }

    .custom-checkbox {
        width: 100%;
        height: calc(100vh - 93vh);
    }

    .checkbox-false {
        width: 18px;
        height: 18px;
    }

    .checkbox-true {
        width: 18px;
        height: 18px;
    }
}