body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}



@font-face {
  font-family: Roboto-Bold;
  src: url('/public/assets/font/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: Roboto-Regular;
  src: url('/public/assets/font/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: Roboto-Medium;
  src: url('/public/assets/font/Roboto/Roboto-Medium.ttf');
}



.background {
  background-image: url('/public/assets/icons/background-logo.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-color {
  color: #000000;
}



.dashboard-btn {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: inherit;
  height: calc(15vh);
  border: 1px solid #6ECA35;
  transition: 0.3s !important;
}

.text-btn {
  color: #000000;
  font-size: 0.8em !important;
}

.dashboard-btn:hover {
  background-color: #3A89CC;
  border: 1px solid #3A89CC;
  cursor: pointer;
  box-shadow: 0px 1px 10px 4px rgb(204, 200, 200);
}

.dashboard-btn:hover .icon-color {
  color: #ffffff;
}

.dashboard-btn:hover .text-btn {
  color: #000000 !important;
}

.form-heading {
  width: 100%;
  height: 75px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-heading span {
  font-family: Roboto-Bold;
  font-size: 2em;
}

.form-heading2 {
  width: 100%;
  /* height: 50px; */
  display: flex;
  align-items: end;
  margin-bottom: 1%;
}

.form-heading2 span {
  font-family: Roboto-Regular;
  font-size: 1.5em;
}

.form-heading1 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1%;
}

.form-heading1 span {
  font-family: Roboto-Bold;
  font-size: 2em;
  color: #000000;
}

.footer {
  position: fixed;
  bottom: 0px;
  width: calc(100%);
  /* background-color: #000000; */
  justify-content: center;
  display: flex;
  /* flex-direction: column !important; */
  align-items: center;
  padding: 3px;
}

.footer-icon {
  justify-content: center;
  display: flex;
  align-items: center;
}

.footer-icon span {
  color: #ffffff;
  font-size: 0.9em;
  font-family: Roboto-Regular;
}

.footer-icon span a {
  color: #ffffff;
  font-size: 1em;
  font-family: Roboto-Regular;
}

.footer-text {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 1%;
  font-family: Roboto-Regular;
}

.footer-text span {
  color: #ffffff;
  font-size: 0.9em;
  font-family: Roboto-Regular;
}

.footer-text span a {
  color: #ffffff;
  font-size: 1em;
  font-family: Roboto-Regular;
}

.terms-info {
  color: #013447;
  font-size: 0.9em !important;
  font-family: Roboto-Regular;
}

.terms-info a {
  color: #013447;
  font-family: Roboto-Regular;
}



::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(207, 207, 207);
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}

.height-handling {
  height: calc(75vh);
}

@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
  }

  .height-handling {
    height: calc(77.2vh);
  }

  .footer {
    /* position: relative; */
    flex-direction: column !important;
  }

  /* ::-webkit-scrollbar {
    display: none;
  } */

  .terms-info {
    font-size: 0.9em !important;
  }

  .handle-mobile {
    max-width: 330px !important;
  }
}


/* * {
  box-sizing: border-box;
} */

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.padding-control {
  padding: 1%;
}

.headigns {
  font-size: 1.1em;
  font-weight: 700;
  font-family: Roboto-Bold;
}

.box-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.box-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.box-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-control {
  height: 50px;

}

.flex-item-left {
  border: 1px solid lightgrey;
  flex: 10%;
}

.flex-item-center {
  border: 1px solid lightgrey;
  flex: 60%;
}

.flex-item-right {
  border: 1px solid lightgrey;
  flex: 30%;
}

.box-1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 97%;
  height: inherit;
}

.box-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  flex: 3%;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}




/* rating-size */
.rating-size>label {
  font-size: 1.4em !important;
}


/* over ride calender color */
/* .crud-color-row {
  display: flex;
}

.mbsc-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
} */

.md-recurring-event-editor-popup .md-recurrence-input .mbsc-textfield-wrapper {
  display: inline-block;
  margin: 3px;
  z-index: 3;
  line-height: normal;
}

.md-recurring-event-editor-popup .md-recurrence-input .mbsc-textfield {
  width: 140px;
  height: 32px;
}

.md-recurring-event-editor-popup .md-recurrence-input-nr .mbsc-textfield {
  width: 90px;
}

.md-recurring-event-editor-popup .md-recurrence-input .mbsc-select-icon {
  top: 50%;
  height: 1.5em;
  margin-top: -0.75em;
}

.md-recurrence-options {
  padding: 0 1em;
  margin: 1em 0;
}

.md-recurrence-ends {
  padding: 0 1em;
  margin: 1.25em 0 -1.25em 0;
}

.md-recurrence-desc {
  font-size: 12px;
  font-weight: normal;
  color: #555;
  margin: 1em 0;
}

.md-hide-elm {
  display: none;
}


.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  /* flex: 1 0; */
  /* flex-basis: 0px; */
  /* overflow: hidden; */
  height: calc(15vh);
}

/* .rbc-time-view {
  min-height: auto !important; 
} */

.rbc-time-view .rbc-row {
  min-height: auto !important;
}



.rbc-header {
  padding: 12px 3px !important;
  border-bottom: none !important;
}

.rbc-header span {
  font-family: Roboto-Bold;
  font-size: 1.3em;
}

.rbc-btn-group button {
  padding: 0.6rem 1rem !important;
}

/* .rbc-time-header {
  margin: 0px !important;
} */

.rbc-day-slot .rbc-events-container {
  margin: 0px !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none !important;
}

body {
  font-family: Roboto-Regular;
}